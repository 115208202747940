<template>
  <div class="tongzhi-details">
    <proviewPdf :pdf-url="imgPdfData" v-if="typePdfFlag"></proviewPdf>
    <div class="imgbox" v-if="!typePdfFlag"><img :src="imgPdfData" alt=""></div>
  </div>
</template>

<script>
import proviewPdf from './components/proviewPdf';
import { request } from '../../../../network';

export default {
  components: {
    proviewPdf
  },
  data () {
    return {
      typePdfFlag: false,
      imgPdfData: '',
      imageType: [ 'jpg', 'png', 'jpeg' ]
    };
  },
  created () {
    this.getURL();
  },
  mounted () {
  },
  methods: {
    async getURL () {
      const { data: res } = await request({
        method: 'GET',
        url: '/pcp/policyDetail/getDeclarationNotice',
        params: {
          id: this.$route.query.id
        }
      });
      if (res.code !== 200) return this.$message.error('信息获取失败');
      if (res.data !== '') {
        const suffix = res.data.substr(res.data.lastIndexOf('.') + 1, res.data.length);
        if (this.imageType.indexOf(suffix) === -1) {
          this.typePdfFlag = true;
        } else {
          this.typePdfFlag = false;
        }
        this.imgPdfData = res.data;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.tongzhi-details {
  height: 75vh;
  border: 10px solid #F5F5F5;
}
.tongzhi-details .imgbox{
  width: 100%;
  height: 75vh;
  overflow: auto;
}
</style>
